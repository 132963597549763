<template>
  <div class="mini-upload-file">
    <h1 class="mini-upload-file__title">文件上传</h1>
    <div class="mini-upload-file__desc">
      支持PDF、DOCX、DOC、XLSX、XLS、PNG、JPG、
      JPEG等常见办公格式，单文件最大为50MB
    </div>
    <div class="mini-upload-file__button-container">
      <van-button class="upload-button" type="primary" @click="handleLaunch"
        >选择文件</van-button
      >
    </div>
     <input type="file" class="inputFile" style="display: none" />
  </div>
</template>
<script>
  import { createSimpleLaunch } from '@/model/launch'

  export default {
    name: 'UploadFile',
    methods: {
      handleLaunch() {
        createSimpleLaunch()
      },
    },
  }
</script>

<style lang="less">
  .mini-upload-file {
    box-sizing: border-box;
    padding: 44px 18px 18px 18px;
    .mini-upload-file__title {
      font-size: 22px;
      color: #434751;
    }
    .mini-upload-file__desc {
      margin-top: 30px;
      font-size: 13px;
      font-weight: 400;
      color: #999999;
    }
    .mini-upload-file__button-container {
      margin-top: 34px;
      .upload-button {
        font-weight: 500;
        width: 100%;
      }
    }
  }
</style>
